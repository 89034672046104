var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{attrs:{"fluid":""}},[_c('v-row',[_c('v-col',{attrs:{"md":"6","cols":"12"}},[_c('base-material-card',{staticClass:"px-5 py-3",staticStyle:{"min-height":"600px"},attrs:{"color":"primary","icon":"mdi-office-building-marker","inline":""},scopedSlots:_vm._u([{key:"after-heading",fn:function(){return [_c('div',{staticClass:"display-1 font-weight-light"},[_vm._v("Informações básicas")])]},proxy:true}])},[_c('v-row',{staticClass:"mt-5"},[_c('v-col',{attrs:{"cols":"12"}},[_c('validation-provider',{attrs:{"rules":"required","name":"Nome"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Nome","outlined":"","maxlength":"100","error-messages":errors},model:{value:(_vm.company.name),callback:function ($$v) {_vm.$set(_vm.company, "name", $$v)},expression:"company.name"}})]}}])})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('validation-provider',{attrs:{"rules":"required","name":"Sigla"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{attrs:{"label":"Sigla","outlined":"","maxlength":"5","error-messages":errors},model:{value:(_vm.company.acronym),callback:function ($$v) {_vm.$set(_vm.company, "acronym", $$v)},expression:"company.acronym"}})]}}])})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":!!_vm.company.logo && _vm.urlValidation(_vm.company.logo) ? 10 : 12}},[_c('v-text-field',{attrs:{"label":"URL para a logo da empresa","rules":[
                function () { return !!_vm.company.logo || 'Este campo é obrigatório'; },
                function () { return (!!_vm.company.logo && _vm.urlValidation(_vm.company.logo)) ||
                  'URL inválida'; }
              ],"outlined":"","maxlength":"255"},model:{value:(_vm.company.logo),callback:function ($$v) {_vm.$set(_vm.company, "logo", $$v)},expression:"company.logo"}})],1),(!!_vm.company.logo && _vm.urlValidation(_vm.company.logo))?_c('v-col',{attrs:{"cols":"2"}},[_c('v-img',{attrs:{"max-height":"70","max-width":"70","src":_vm.company.logo}})],1):_vm._e()],1)],1)],1),_c('v-col',{attrs:{"md":"6","cols":"12"}},[_c('base-material-card',{staticClass:"px-5 py-3",staticStyle:{"min-height":"600px"},attrs:{"color":"primary","icon":"mdi-map-marker-plus-outline","inline":""},scopedSlots:_vm._u([{key:"after-heading",fn:function(){return [_c('div',{staticClass:"display-1 font-weight-light"},[_vm._v(" Posição da empresa no mapa ")])]},proxy:true}])},[_c('v-row',{staticClass:"mt-5"},[_c('v-col',{attrs:{"cols":"12"}},[_c('v-alert',{staticClass:"py-1 px-1",attrs:{"dense":"","outlined":"","type":"info","color":"blue"}},[_vm._v(" Clique em um local no mapa para selecionar a localização aproximada da empresa. ")])],1),_c('v-col',{attrs:{"cols":"12"}},[_c('select-point-on-map',{attrs:{"zoom":_vm.company.zoom,"point":_vm.company.capital},on:{"update:zoom":function($event){return _vm.$set(_vm.company, "zoom", $event)},"update:point":function($event){return _vm.$set(_vm.company, "capital", $event)}}})],1)],1)],1)],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-btn',{staticClass:"mt-n5 mr-0",staticStyle:{"float":"right"},attrs:{"color":"primary","min-width":"100","disabled":_vm.btnSaveDisabled,"loading":_vm.loading},on:{"click":function($event){return _vm.save()}}},[_vm._v(" Salvar ")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }