<template>
  <v-container fluid>
    <v-row>
      <v-col
        md="6"
        cols="12"
      >
        <base-material-card
          color="primary"
          icon="mdi-office-building-marker"
          inline
          class="px-5 py-3"
          style="min-height: 600px"
        >
          <template v-slot:after-heading>
            <div class="display-1 font-weight-light">Informações básicas</div>
          </template>
          <v-row class="mt-5">
            <v-col cols="12">
              <validation-provider
                v-slot="{ errors }"
                rules="required"
                name="Nome"
              >
                <v-text-field
                  label="Nome"
                  v-model="company.name"
                  outlined
                  maxlength="100"
                  :error-messages="errors"
                />
              </validation-provider>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <validation-provider
                v-slot="{ errors }"
                rules="required"
                name="Sigla"
              >
                <v-text-field
                  label="Sigla"
                  v-model="company.acronym"
                  outlined
                  maxlength="5"
                  :error-messages="errors"
                />
              </validation-provider>
            </v-col>
          </v-row>
          <v-row>
            <v-col
              :cols="!!company.logo && urlValidation(company.logo) ? 10 : 12"
            >
              <v-text-field
                label="URL para a logo da empresa"
                v-model="company.logo"
                :rules="[
                  () => !!company.logo || 'Este campo é obrigatório',
                  () =>
                    (!!company.logo && urlValidation(company.logo)) ||
                    'URL inválida'
                ]"
                outlined
                maxlength="255"
              />
            </v-col>
            <v-col
              cols="2"
              v-if="!!company.logo && urlValidation(company.logo)"
            >
              <v-img
                max-height="70"
                max-width="70"
                :src="company.logo"
              />
            </v-col>
          </v-row>
        </base-material-card>
      </v-col>
      <v-col
        md="6"
        cols="12"
      >
        <base-material-card
          color="primary"
          icon="mdi-map-marker-plus-outline"
          inline
          class="px-5 py-3"
          style="min-height: 600px"
        >
          <template v-slot:after-heading>
            <div class="display-1 font-weight-light">
              Posição da empresa no mapa
            </div>
          </template>
          <v-row class="mt-5">
            <v-col cols="12">
              <v-alert
                dense
                outlined
                type="info"
                color="blue"
                class="py-1 px-1"
              >
                Clique em um local no mapa para selecionar a localização
                aproximada da empresa.
              </v-alert>
            </v-col>
            <v-col cols="12">
              <select-point-on-map
                :zoom.sync="company.zoom"
                :point.sync="company.capital"
              />
            </v-col>
          </v-row>
        </base-material-card>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="12">
        <v-btn
          color="primary"
          min-width="100"
          style="float: right"
          class="mt-n5 mr-0"
          @click="save()"
          :disabled="btnSaveDisabled"
          :loading="loading"
        >
          Salvar
        </v-btn>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import CompanyService from '@/services/CompanyService';
import routes from '@/store/modules/routes';

export default {
  name: 'CompanyEdit',

  components: {
    SelectPointOnMap: () => import('@/components/general/SelectPointOnMap.vue')
  },

  data: () => ({
    company: {
      name: '',
      acronym: '',
      logo: '',
      zoom: 6,
      capital: []
    },
    loading: false
  }),

  mounted() {
    if (this.$route.params.id) this.getCompany(this.$route.params.id);
  },

  computed: {
    btnSaveDisabled() {
      const { name, acronym, logo, zoom, capital } = this.company;

      return !(
        name &&
        acronym &&
        logo &&
        this.urlValidation(logo) &&
        zoom &&
        capital.length === 2
      );
    }
  },

  methods: {
    getCompany(id) {
      CompanyService.getCompanyById(id)
        .then((response) => {
          const { name, acronym, logo, zoom, capital } = response;

          this.company = {
            ...this.company,
            name,
            acronym,
            logo,
            zoom,
            capital
          };
        })
        .catch(() => {
          this.$toast.error('Erro ao buscar os dados da empresa.', '', {
            position: 'topRight'
          });
        });
    },

    urlValidation(url) {
      return /^(http|https):\/\/[^ "]+$/.test(url);
    },

    save() {
      this.loading = true;

      let data = {
        ...this.company,
        id: this.$route.params.id ? this.$route.params.id : null
      };

      CompanyService.save(data)
        .then(() => {
          this.$toast.success('Empresa salva com sucesso.', '', {
            position: 'topRight'
          });
          this.$router.replace({
            name: routes.state.appRoutes['EMPRESAS_LISTAR']
          });
        })
        .catch(() => {
          this.$toast.error('Erro ao salvar a empresa.', '', {
            position: 'topRight'
          });
        })
        .finally(() => (this.loading = false));
    }
  }
};
</script>
